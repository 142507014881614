<template>
  <div
    :id="device.id"
    class="app-device-block"
    :class="{ 'app-device-block--show-spec': showSpecMob }"
  >
    <div class="app-device-block__container">
      <div v-if="isNew" class="app-device-block__label">{{ $t('label.device.new') }}</div>
      <div class="app-device-block__image">
        <img :src="$t(device.image)" alt="">
      </div>

      <div class="app-device-block__data">
        <div class="app-device-block__name">{{ device.name }}</div>
        <div class="app-device-block__type">{{ device.type }}</div>
        <div class="app-device-block__lead">{{ device.lead }}</div>
        <div class="app-device-block__description">{{ device.description }}</div>
        <div class="app-device-block__buttons">

          <button
            class="btn btn--spec"
            @click="toggleSpecification"
          >{{ $t('label.button.specification') }}</button>
          <a
            v-if="false && device.button?.first"
            class="btn"
            :href="device.button.first.url"
          >{{ device.button.first.text }}</a>
          <a
            v-if="false && device.button?.second"
            class="btn btn--secondary"
            :href="device.button.second.url"
          >{{ device.button.second.text }}</a>
        </div>
      </div>

      <div class="app-device-block__spec">
        <template
          v-for="(value, key) in device.spec"
        >
          <div
            v-if="device.spec[key]"
            :key="key"
            class="app-device-block__spec-item"
          >
            <span class="app-device-block__spec-label">{{ $t(`label.device.${key}`) }}: </span>
            <span class="app-device-block__spec-value">{{ device.spec[key] }} </span>
          </div>
        </template>
        <button
          class="btn btn--secondary"
          @click="closeSpecification"
        >{{ $t('label.button.close') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['device'],
  data() {
    return {
      showSpecMob: false,
    };
  },
  computed: {
    isNew() {
      return this.device.new && this.device.new !== 'false' && this.device.new !== '0';
    },
  },
  methods: {
    toggleSpecification() {
      this.showSpecMob = !this.showSpecMob;
    },
    closeSpecification() {
      this.showSpecMob = false;
    },
    openSpecification() {
      this.showSpecMob = true;
    },
  },
};
</script>

<style lang="scss">
.app-device-block {
  $root: &;

  margin-top: 45px;
  margin-bottom: 90px;
  background: #f8f7f7;
  position: relative;

  &__container {
    @include container;

    display: flex;
  }

  &__label {
    position: absolute;
    padding: 10px 40px;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    color: #FFFFFF;
    transform: translateY(-50%);
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        184.27deg,
        rgba(255, 1, 1, 0.85) 8.61%,
        rgba(255, 72, 3, 0.75) 98.64%,
        rgba(255, 94, 3, 0.65) 126.2%
      );
      transform: skewX(-15deg);
      z-index: -1;
    }
  }

  &__image{
    flex: 1 1 440px;
    max-width: 440px;
    padding: 20px;
  }

  &__data {
    flex: 0 0 440px;
    max-width: 440px;

    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 5%;
  }

  &__spec {
    flex: 1 1 370px;
    max-width: 370px;

    padding-top: 32px;
    padding-bottom: 32px;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 43px;
    line-height: 138.19%;
    color: #CA1025;
    text-transform: uppercase;
  }

  &__type {
    font-weight: 500;
    font-size: 52px;
    line-height: 110%;
    color: #343434;
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  &__lead {
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;
    color: #292929;
    margin-bottom: 8px;
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #292929;
  }

  &__buttons {
    margin-top: 32px;
    margin-bottom: 24px;
    .btn {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }

      margin-bottom: 8px;

      &--spec {
        display: none;
      }
    }
  }

  &__spec {
    display: flex;
    flex-flow: column;
    &-item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &-label,
    &-value {
      font-weight: 400;
      font-size: 18px;
      line-height: 98.5%;
      color: #756B6B;
      white-space: nowrap;
    }

    &-label {
      padding-right: 8px;
    }

    &-value {
      font-weight: 700;
    }

    .btn {
      display: none;
    }
  }

  @include media-bp(tab) {
    &__label {
      font-size: 30px;
      line-height: 32px;
      padding: 6px 24px;
    }

    &__image {
      flex: 1 1 33%;
      max-width: 33%;
      padding: 12px;
    }

    &__data,
    &__spec {
      flex: 1 1 50%;
      max-width: 1 1 50%;
    }

    &__name {
      font-size: 28px;
    }

    &__type {
      font-size: 32px;
    }

    &__lead {
      font-size: 16px;
    }

    &__description {
      font-size: 16px;
    }

    &__spec {
      &-item {
        margin-bottom: 12px;
      }

      &-label,
      &-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
        color: #756B6B;
        white-space: nowrap;
      }

      &-label {
        padding-right: 8px;
      }

      &-value {
        font-weight: 700;
      }
    }
  }

  @include media-bp(mob) {
    margin-top: 200px;

    &__container {
      flex-direction: column;
    }

    &__label {
      font-size: 25px;
      line-height: 28px;
      padding: 2px 12px;
    }

    &__image,
    &__data,
    &__spec {
      flex: 0 0 auto;
      max-width: 100%;
      padding: 0;
    }

    &__image {
      margin: -200px auto 24px;

      width: 180px;
      height: 280px;

      align-items: flex-end
    }

    &__data {
      margin-top: 0;
    }

    &__name {
      font-size: 36px;
      line-height: 110%;

      margin-bottom: 8px;
    }

    &__type {
      font-size: 48px;
      line-height: 110%;

      margin-bottom: 16px;
    }

    &__lead {
      font-size: 16px;
      line-height: 110%;

      margin-bottom: 10px;
    }

    &__description {
      font-size: 16px;
      line-height: 110%;
    }

    &__spec {
      background: #f8f7f7;

      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;

      z-index: 2;

      padding: 40px 30px 18px;

      display: none;
      #{$root}--show-spec & {
        display: flex;
      }

      margin-top: 8px;

      &-item {
        margin-bottom: 12px;
      }

      &-label,
      &-value {
        font-size: 18px;
      }

      .btn {
        display: initial;
        align-self: center;
        margin-top: 24px;
      }
    }

    &__buttons {
      .btn {
        margin-right: 12px;

        &--spec {
          display: flex;
        }
      }
    }
  }
}
</style>
