<template>
  <div class="app-device-advertisement" :style="styleVars">
    <div class="app-device-advertisement__container">
      <div class="app-device-advertisement__type">{{ device.type }}</div>
      <div class="app-device-advertisement__data">
        <div class="app-device-advertisement__name">{{ device.name }}</div>
        <div class="app-device-advertisement__description">{{ device.description }}</div>
      </div>
      <div class="app-device-advertisement__image">
        <img :src="$t(device.image)" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['device'],
  computed: {
    styleVars() {
      const styleVars = {};
      if (this.device['image-top-overlay-percentage']) {
        styleVars['--image-top-overlay-percentage'] = this.device['image-top-overlay-percentage'];
      }
      if (this.device['image-bottom-overlay-percentage']) {
        styleVars['--image-bottom-overlay-percentage'] = this.device['image-bottom-overlay-percentage'];
      }
      return styleVars;
    },
  },
};
</script>

<style lang="scss">
.app-device-advertisement {
  --image-width-ratio: 0.6;

  margin-top: calc(var(--image-top-overlay-percentage) * var(--image-width-ratio));
  margin-bottom: calc(var(--image-bottom-overlay-percentage) * var(--image-width-ratio));

  background: #CA1025;
  position: relative;

  &__container {
    @include container;

    display: flex;
  }

  &__data {
    flex: 1 1 calc(100% - 100% * var(--image-width-ratio));
    max-width: calc(100% - 100% * var(--image-width-ratio));
    padding-right: 40px;
  }

  &__type {
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    color: #343434;
    text-transform: uppercase;
    position: absolute;
    bottom: 100%;
  }

  &__name {
    font-weight: 700;
    font-size: 40px;
    line-height: 138.19%;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 138.19%;
    color: #FFFFFF;
  }

  &__image {
    flex: 1 1 calc(100% * var(--image-width-ratio));
    max-width: calc(100% * var(--image-width-ratio));

    img {
      width: 100%;
      max-width: 100%;
      margin-top: calc(-1 * var(--image-top-overlay-percentage));
      margin-bottom: calc(-1 * var(--image-bottom-overlay-percentage));
    }
  }

  @include media-bp(tab) {
    &__data {
      padding-right: 20px;
    }

    &__type {
      font-size: 18px;
    }

    &__name {
      font-size: 20px;
      margin-bottom: 12px;
    }

    &__description {
      font-size: 14px;
    }
  }

  @include media-bp(mob) {
    display: none;
  }
}
</style>
