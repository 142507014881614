<template>
  <app-page-layout
    class="page-devices"
    v-bind="content"
  >
    <template
      v-for="(device, index) in content.data"
    >
      <app-device-advertisement
        v-if="device.adv"
        :key="index"
        :device="device.adv"
      />
      <app-device-block
        :key="index"
        :device="device"
      />
    </template>
  </app-page-layout>
</template>

<script>
import AppPageLayout from '@/components/app-page-layout';
import AppDeviceBlock from '@/components/app-device-block';
import AppDeviceAdvertisement from '@/components/app-device-advertisement';

export default {
  components: {
    AppPageLayout,
    AppDeviceBlock,
    AppDeviceAdvertisement,
  },
  computed: {
    content() {
      return this.$t('page.devices');
    },
  },
};
</script>

<style lang="scss">

</style>
